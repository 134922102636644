import React, { useState, useMemo, useCallback, useRef, useEffect } from 'react'
import { AgGridReact } from 'ag-grid-react';
import ContentHeader from '../Commons/Layouts/ContentHeader';
import { fetchPayrollRunLineItems, updatePayrollRunLineItemPayItem, fetchPayrollRun, fetchRunPayroll } from '../../services/payrollRunServices';
import { formatCurrency, formatDate, formatDateOnly, sizeofJSONInMB, sizeofLocalStorageInMB } from '../../utils/formatUtils';
import _ from 'lodash';
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import PayMethodTypeRenderer from '../Templates/CellRenderers/PayMethodTypeRenderer';
import PayMethodTypeEditor from '../Templates/Editor/PayMethodTypeEditor';
import { toast } from 'react-toastify';
import { showToast } from '../../utils/toastUtils';
import { deletePayrollRunLineItem } from '../../services/payrollRunServices';
import ActionRenderer from '../Templates/CellRenderers/PayrollRuns/ActionRenderer';
import PayrollRunModal from './PayrollRunModal';
import UnitsOfPayTooltip from '../Commons/Tooltips/UnitsOfPayTooltip';
import VacationPayUnit from '../Commons/Tooltips/VacationPayUnit';
import UnitsOfPayRenderer from '../Templates/CellRenderers/UnitsOfPayRenderer';
import VacationPayRenderer from '../Templates/CellRenderers/VacationPayRenderer';
import EmployeeNameRenderer from '../Templates/CellRenderers/EmployeeNameRenderer';
import EmployeeNameTooltip from '../Commons/Tooltips/EmployeeNameTooltip';
import { renderPayrollPeriod } from '../../utils/payrollRunUtils';
import { isAccountUser } from '../../utils/roleUtils';
import AuthorizationError from '../Commons/Authorization/AuthorizationError';
import { NumericCellEditor } from '../Commons/Editor/NumericCellEditor';
import { setPayrollRunLineItemsData, setPayrollRunLineItem } from '../../redux/payrollRunLineItems/payrollRunLineItemsReducer';
import PaystubReportMenuModal from './PaystubReportMenuModal';
import EmployerRemittanceReportMenuModal from './EmployerRemittanceReportMenuModal';
import PayrollReportMenuModal from './PayrollReportMenuModal';
import PD7AReportMenuModal from './PD7AReportMenuModal';
import { editPayMethod, editOvertimePayUnit, overtimeValueGetter } from '../../utils/payrollRunUtils';
import moment from 'moment';

function EditPayrollPage() {
  const dispatch = useDispatch();

  const { state } = useLocation();
  const accountId = useSelector((state) => state.auth.user.accountId);
  const userType = useSelector((state) => state.auth.user.userType);
  const payrollRunLineItems = useSelector((state) => state.payrollRunLineItems);

  const gridRef = useRef();
  const [rowData, setRowData] = useState([]);
  const [payrollRun, setPayrollRun] = useState([]);
  const [editablePage, setEditablePage] = useState(true);

  const togglePayrollRun = (isEdit = false, contactId = null) => {
    setModal(!modal);
    setTimeout(() => {
      gridRef.current.api.sizeColumnsToFit();
    }, 50);
  }

  const removeSelected = useCallback(async (params) => {
    const selectedNode = gridRef.current.api.getSelectedNodes();

    const response = await deletePayrollRunLineItem(selectedNode[0].data["id"])
    showToast({
      type: `${response.ok ? 'success' : 'error'}`,
      message: `${response.ok ? 'Successfully deleted' : 'Failed to delete'} line item.`
    });

    if (response.ok) {
      fillPayrollRunLineItemList();
      setModal(false);
    }

  }, [rowData]);

  const columnDefinitions = (isEditable, isPrintableLayout = false, hideActionButtonOnly = false) => {
    const defs = [
      {
        field: '#',
        width: 50,
        minWidth: 50,
        suppressSizeToFit: true,
        cellClass: ['no-borders', 'payroll-column-value'],
        cellStyle: { fontWeight: 'bold' },
        headerClass: 'payroll-column-header'
      },
      {
        field: 'Employee',
        minWidth: 150,
        width: 150,
        headerName: 'Employee',
        suppressSizeToFit: true,
        cellClass: ['no-borders', 'payroll-column-value'],
        cellRenderer: EmployeeNameRenderer,
        headerClass: 'payroll-column-header',
        tooltipComponent: EmployeeNameTooltip,
        tooltipField: 'Employee',
        tooltipComponentParams: { isEditable: isEditable }
      },
      {
        field: 'Pay Method Type',
        headerName: 'Pay Method',
        minWidth: 110,
        width: 110,
        suppressSizeToFit: true,
        cellClass: ['no-borders', 'payroll-column-value'],
        cellRendererSelector: (params) => {
          if (params.data['Pay Method Type'] !== undefined) {
            return {
              component: PayMethodTypeRenderer
            }
          } else {
            return undefined;
          }
        },
        cellEditor: PayMethodTypeEditor,
        cellEditorParams: {
          gridRef: gridRef,
        },
        editable: isEditable,
        headerClass: 'payroll-column-header',
      },
      {
        field: 'Units of Pay',
        minWidth: 110,
        cellClass: ['no-borders', 'payroll-column-value'],
        cellRenderer: UnitsOfPayRenderer,
        getQuickFilterText: params => { return params.value; },
        cellEditor: NumericCellEditor,
        editable: params => { return editPayMethod(params, isEditable) },
        headerClass: 'payroll-column-header',
        tooltipComponent: UnitsOfPayTooltip,
        tooltipField: 'Units of Pay',
        tooltipComponentParams: { isEditable: isEditable }
      },
      {
        field: 'Pay Per Unit',
        minWidth: 110,
        cellClass: ['no-borders', 'payroll-column-value'],
        valueFormatter: params => { return formatCurrency(params.value) },
        cellEditor: NumericCellEditor,
        getQuickFilterText: params => { return formatCurrency(params.value); },
        editable: isEditable,
        headerClass: 'payroll-column-header'
      },
      {
        field: 'Overtime Pay Unit',
        headerName: 'OT Unit',
        minWidth: 90,
        cellClass: ['no-borders', 'payroll-column-value'],
        getQuickFilterText: params => { return params.value; },
        valueGetter: (params) => overtimeValueGetter(params),
        cellEditor: NumericCellEditor,
        editable: params => { return editOvertimePayUnit(params, isEditable) },
        headerClass: 'payroll-column-header'
      },
      {
        field: 'Vacation Pay',
        headerName: 'Vac. Pay',
        minWidth: 110,
        cellRenderer: VacationPayRenderer,
        cellClass: ['no-borders', 'payroll-column-value'],
        valueFormatter: params => { return formatCurrency(params.value) },
        cellEditor: NumericCellEditor,
        getQuickFilterText: params => { return formatCurrency(params.value); },
        headerClass: 'payroll-column-header',
        tooltipComponent: VacationPayUnit,
        tooltipField: 'Vacation Pay', tooltipComponentParams: { isEditable: isEditable }
      },
      {
        field: 'Gross Pay',
        minWidth: 100,
        cellClass: ['no-borders', 'payroll-column-value'],
        valueFormatter: params => { return formatCurrency(params.value) },
        cellEditor: NumericCellEditor,
        getQuickFilterText: params => { return formatCurrency(params.value); },
        headerClass: 'payroll-column-header',
        tabToNextCell: false
      },
      {
        field: 'Federal Tax',
        headerName: 'Fed. Tax',
        minWidth: 100,
        cellClass: ['no-borders', 'payroll-column-value'],
        valueFormatter: params => { return formatCurrency(params.value) },
        cellEditor: NumericCellEditor,
        getQuickFilterText: params => { return formatCurrency(params.value); },
        headerClass: 'payroll-column-header'
      },
      {
        field: 'Provincial Tax',
        headerName: 'Prov. Tax',
        minWidth: 100,
        cellClass: ['no-borders', 'payroll-column-value'],
        valueFormatter: params => { return formatCurrency(params.value) },
        cellEditor: NumericCellEditor,
        getQuickFilterText: params => { return formatCurrency(params.value); },
        headerClass: 'payroll-column-header'
      },
      {
        headerName: 'CPP',
        marryChildren: true,
        headerClass: 'grouped-columns-header',
        cellClass: 'grouped-columns-value',
        children: [
          {
            field: 'Employee CPP',
            headerName: "Employee",
            minWidth: 100,
            cellClass: ['no-borders', 'payroll-column-value'],
            valueFormatter: params => { return formatCurrency(params.value) },
            cellEditor: NumericCellEditor,
            getQuickFilterText: params => { return formatCurrency(params.value); },
            headerClass: 'payroll-column-header'
          },
          {
            field: 'Employer CPP',
            headerName: "Employer",
            minWidth: 100,
            cellClass: ['no-borders', 'payroll-column-value'],
            valueFormatter: params => { return formatCurrency(params.value) },
            cellEditor: NumericCellEditor,
            getQuickFilterText: params => { return formatCurrency(params.value); },
            headerClass: 'payroll-column-header'
          },
          {
            field: 'YTD CPP',
            headerName: "YTD",
            minWidth: 100,
            width: 100,
            cellClass: ['no-borders', 'payroll-column-value'],
            valueFormatter: params => { return formatCurrency(params.value) },
            cellEditor: NumericCellEditor,
            getQuickFilterText: params => { return formatCurrency(params.value); },
            headerClass: 'payroll-column-header'
          },
        ]
      },
      {
        headerName: 'EI',
        marryChildren: true,
        headerClass: 'grouped-columns-header',
        children: [
          {
            field: 'Employee EI',
            headerName: "Employee",
            minWidth: 100,
            cellClass: ['no-borders', 'payroll-column-value'],
            valueFormatter: params => { return formatCurrency(params.value) },
            cellEditor: NumericCellEditor,
            getQuickFilterText: params => { return formatCurrency(params.value); },
            headerClass: 'payroll-column-header'
          },
          {
            field: 'Employer EI',
            headerName: "Employer",
            minWidth: 100,
            cellClass: ['no-borders', 'payroll-column-value'],
            valueFormatter: params => { return formatCurrency(params.value) },
            cellEditor: NumericCellEditor,
            getQuickFilterText: params => { return formatCurrency(params.value); },
            headerClass: 'payroll-column-header'
          },
          {
            field: 'YTD EI',
            headerName: "YTD",
            minWidth: 100,
            cellClass: ['no-borders', 'payroll-column-value'],
            valueFormatter: params => { return formatCurrency(params.value) },
            cellEditor: NumericCellEditor,
            getQuickFilterText: params => { return formatCurrency(params.value); },
            headerClass: 'payroll-column-header'
          },
        ]
      },
      {
        field: 'Other Deductions',
        headerName: 'Other',
        minWidth: 100,
        cellClass: ['no-borders', 'payroll-column-value'],
        valueFormatter: params => { return formatCurrency(params.value) },
        cellEditor: NumericCellEditor,
        getQuickFilterText: params => { return formatCurrency(params.value); },
        headerClass: 'payroll-column-header'
      },
      {
        field: 'Total Pay',
        minWidth: 100,
        cellClass: ['no-borders', 'payroll-column-value'],
        valueFormatter: params => { return formatCurrency(params.value) },
        cellEditor: NumericCellEditor,
        getQuickFilterText: params => { return formatCurrency(params.value); },
        headerClass: 'payroll-column-header',
        tabToNextCell: false
      },
      {
        field: 'Action',
        headerName: '',
        minWidth: 100,
        width: 120,
        cellRenderer: ActionRenderer,
        cellRendererParams: {
          removeSelected: removeSelected,
          lineItemGridRef: gridRef,
          editable: isEditable
        },
        autoHeight: true,
        headerClass: 'payroll-column-header-header',
        cellClass: 'd-flex flex-row-reverse',
      },
    ]

    return defs;
  }

  // useStates
  const [columnDefs, setColumnDefs] = useState();
  const [payrollRunLineItemList, setPayrollRunLineItemList] = useState([]);
  const [modal, setModal] = useState(false);
  const [reportMenuModal, setReportMenuModal] = useState(false);
  const [employerRemittanceMenuModal, setEmployerRemittanceMenuModal] = useState(false);
  const [payrollReportMenuModal, setPayrollReportMenuModal] = useState(false);
  const toggle = () => setModal(!modal);
  const toggleReportMenuModal = () => setReportMenuModal(!reportMenuModal);
  const toggleEmployerRemittanceReportMenuModal = () => setEmployerRemittanceMenuModal(!employerRemittanceMenuModal);
  const togglePayrollReportMenuModal = () => setPayrollReportMenuModal(!payrollReportMenuModal);


  // useEffects
  useEffect(() => {
    fillPayrollRun();
    fillPayrollRunLineItemList();
  }, [])

  useEffect(() => {
    let data = [];
    _.each(payrollRunLineItemList, (item, index) => {
      data.push({
        '#': index + 1,
        'id': item.payrollRunLineItemId,
        "Employee": item.userName,
        "User Id": item.userId,
        "First Pay Item Id": item.payrollRunLineItemPayItem[0]?.payrollRunLineItemPayItemId,
        "Pay Method Type": item.payrollRunLineItemPayItem[0]?.payMethodType,
        "Units of Pay": item.payrollRunLineItemPayItem[0]?.unitsOfPay,
        "Pay Per Unit": item.payrollRunLineItemPayItem[0]?.payPerUnit,
        "Overtime Pay Unit": item.payrollRunLineItemPayItem[0]?.overtimePayUnit,
        "Vacation Pay": item.vacationPay,
        "Gross Pay": item.grossPay,
        "Provincial Tax": item.provincialTaxes,
        "Federal Tax": item.federalTaxes,
        "Employee CPP": item.cppContributionEmp,
        "Employer CPP": item.cppContributionOrg,
        "YTD CPP": item.cppContributionYTD,
        "Employee EI": item.eiContributionEmp,
        "Employer EI": item.eiContributionOrg,
        "YTD EI": item.eiContributionYTD,
        "Other Deductions": item.otherDeductions,
        "Total Pay": item.totalPay,
        "Account Id": item.accountId,
        "Payroll Run Id": item.payrollRunId,
        "Vacation Type": item.user.payroll.vacationType,
        "data": item.payrollRunLineItemPayItem,
      });
    });
    setRowData(data);
  }, [payrollRunLineItemList])

  useEffect(() => {
    setPayrollRunLineItemList(payrollRunLineItems.data);
  }, [payrollRunLineItems.data])

  const defaultColDef = useMemo(() => {
    return {
      cellStyle: {
        whiteSpace: 'pre-wrap',
        overflowWrap: 'break-word',
        textAlign: 'left',
      },
      resizable: true,
      cellHeight: 10,
    };
  }, []);

  const onGridReady = useCallback((params) => {
    setTimeout(() => {
      gridRef.current.api.sizeColumnsToFit();
    }, 50);
  }, []);

  const fillPayrollRun = async () => {
    if (!isAccountUser(userType)) {
      let response = await fetchPayrollRun(state.payrollRunId);

      setColumnDefs(columnDefinitions(!response.statusRun));
      setEditablePage(response.statusRun);
      setPayrollRun(response);
    }

  }


  const fillPayrollRunLineItemList = async () => {
    let response = null;
    if (!isAccountUser(userType)) {
      response = await fetchPayrollRunLineItems(state)
      dispatch(setPayrollRunLineItemsData(response));
      setPayrollRunLineItemList(response)
    }

  }

  const filterData = (searchQuery) => {
    gridRef.current.api.setQuickFilter(searchQuery);
  }

  const onColumnsSizeChanged = (params) => {
    var gridWidth = document.getElementById("grid-wrapper").offsetWidth;
    var columnsToShow = [];
    var columnsToHide = [];
    var totalColsWidth = 0;
    var allColumns = params.columnApi.getAllColumns();
    for (var i = 0; i < allColumns.length; i++) {
      let column = allColumns[i];
      totalColsWidth += column.getMinWidth();
      if (totalColsWidth > gridWidth) {
        columnsToHide.push(column.colId);
      } else {
        columnsToShow.push(column.colId);
      }
    }
    params.columnApi.setColumnsVisible(columnsToShow, true);
    params.columnApi.setColumnsVisible(columnsToHide, false);
    params.api.sizeColumnsToFit();
  }

  const onGridSizeChanged = (params) => {
    params.api.sizeColumnsToFit();
  }

  const submitRowEdit = async (params) => {
    if (params.value === "" || params.value === ".") return
    const selectedNode = gridRef.current.api.getSelectedNodes();

    let response = null;

    const payload = {
      payrollRunId: parseInt(selectedNode[0].data["Payroll Run Id"]),
      payrollRunLineItemId: parseInt(selectedNode[0].data["id"]),
      payrollRunLineItemPayItemId: parseInt(selectedNode[0].data["First Pay Item Id"]),
      payMethodType: parseInt(selectedNode[0].data["Pay Method Type"]),
      unitsOfPay: parseInt(selectedNode[0].data["Pay Method Type"]) === 0 ? 1 : selectedNode[0].data["Units of Pay"],
      payPerUnit: selectedNode[0].data["Pay Per Unit"],
      overtimePayUnit: selectedNode[0].data["Overtime Pay Unit"],
      accountId: selectedNode[0].data["Account Id"]
    };

    response = await updatePayrollRunLineItemPayItem(payload, selectedNode[0].data["First Pay Item Id"]);

    if (response.ok) {

      const data = await response.json();
      dispatch(setPayrollRunLineItem(data));
    }

    showToast({
      type: `${response.ok ? 'success' : 'error'}`,
      message: `${response.ok ? 'Successfully updated' : 'Failed to update'} line item.`
    });
  }

  const onCellKeyDown = useCallback(
    async (params) => {
      if (params.event.keyCode === 9 && params.column.getColDef().field === 'Total Pay') {
        if (params.value === "" || params.value === ".") return
        submitRowEdit(params)
        setTimeout(() => {
          const nextNode = params.api.getRowNode(params.rowIndex + 1);
          if (nextNode !== undefined) {
            params.api.stopEditing(false);
            params.api.startEditingCell({
              rowIndex: params.rowIndex + 1,
              colKey: "Pay Method Type"
            });
          }
        }, 1000);
        return true; // prevent default tab behavior
      }

      if (params.event.shiftKey && params.event.keyCode === 9 && params.column.getColDef().field === 'Overtime Pay Unit') {
        if (params.value === "" || params.value === ".") return
        submitRowEdit(params)
        setTimeout(() => {
          const previousNode = params.api.getRowNode(params.rowIndex - 1);
          if (previousNode !== undefined) {
            params.api.stopEditing(false);
            params.api.startEditingCell({
              rowIndex: params.rowIndex - 1,
              colKey: "Total Pay"
            });
          }
        }, 1000);
        return true;
      }
    },
  );

  const onCellEditingStopped = useCallback(
    async (params) => {
      const editingCells = params.api.getEditingCells();
      if (params.newValue === "" || params.newValue === ".") {
        const toastId = "invalid-value-error"
        if (!toast.isActive(toastId)) {
          showToast({
            type: 'error',
            message: 'Please enter a valid value',
            id: toastId
          });
        }
        params.api.stopEditing(false);
        params.api.startEditingCell({
          rowIndex: params.rowIndex,
          colKey: params.column.getId()
        });
        params.node.setDataValue(params.colDef.field, params.oldValue);
        return;
      }
      if (params.value === "" || params.value === "." || !_.isEmpty(editingCells)) return
      await submitRowEdit(params)
    },
  );

  const onCellClicked = (params) => {
    const editingCells = params.api.getEditingCells();
    if (params.value !== "" || params.value !== ".") return
    setTimeout(() => {
      if (params.value === "" || params.value === "." || _.isEmpty(editingCells)) {
        params.api.stopEditing(false);
        params.api.startEditingCell({
          rowIndex: params.rowIndex,
          colKey: params.column.getId()
        });
      }
    }, 500);
  }

  const onCellDoubleClicked = (params) => {
    const editingCells = params.api.getEditingCells();
    if (params.value !== "" || params.value !== ".") return
    setTimeout(() => {
      if (params.value === "" || params.value === "." || _.isEmpty(editingCells)) {
        params.api.stopEditing(false);
        params.api.startEditingCell({
          rowIndex: params.rowIndex,
          colKey: params.column.getId()
        });
      }
    }, 500);
  }

  const runPayroll = async () => {
    let response = null;

    var processingDate = moment().clone().hour(7).minute(0).second(0).format('YYYY-MM-DD');
    const payload = {
      statusRun: true,
      accountId: payrollRun.accountId,
      payrollRunId: payrollRun.payrollRunId,
      processingDate: processingDate
    };

    response = await fetchRunPayroll(payload, state);
    showToast({
      type: `${response.ok ? 'success' : 'error'}`,
      message: `${response.ok ? 'Successfully ran' : 'Failed to run'} payroll.`
    });

    if (response.ok) {
      // setStatus(value)
      fillPayrollRunLineItemList();
      fillPayrollRun();
    }

    togglePayrollRun();
  }

  const onFirstDataRendered = useCallback(async (params) => {
    setTimeout(() => {
      gridRef.current.api.sizeColumnsToFit();
    }, 200);
  })

  return (
    <div>
      {
        isAccountUser(userType) && (
          <AuthorizationError />
        )
      }
      {
        !isAccountUser(userType) && (
          <>
            <div className='content-header-container d-flex flex-row align-items-center justify-content-between non-printable'>
              <div className='me-auto'>
                <ContentHeader
                  title="Edit Payroll"
                  dataCount={payrollRunLineItemList.length}
                  filterData={filterData}
                  showStatus={true}
                  editablePage={!editablePage}
                  date={payrollRun?.payrollRunDate}
                  backButton={true}
                  backButtonLink='/payroll-runs'
                />
              </div>
              <div className={`content-data-text me-4 ${(payrollRun?.payrollRunDate !== null && payrollRun?.payrollRunDate !== undefined) && editablePage ? '' : 'd-none'}`}>
                This payroll was run on <b>{formatDateOnly(payrollRun?.payrollRunDate)}</b>
              </div>
              <button className="btn btn-lg btn-primary me-2 w-15" onClick={() => setEmployerRemittanceMenuModal(true)}>Employer Remittance Report</button>
              <button className="btn btn-lg btn-primary me-2 w-15" onClick={() => setReportMenuModal(true)}>Paystub Report</button>
              {payrollRun?.payrollRunType === 1 &&
                <button className="btn btn-lg btn-primary me-2 w-15" onClick={() => setPayrollReportMenuModal(true)}>Payroll Report</button>
              }
            </div>
            <div className="content-body-container row">
              {/* <div className={`${isFormHidden ? 'col-12' : 'col-12 col-lg-5 min-vh-25'}`}> */}
              <div className="col-12">
                <div id="payrollRunGrid" className={`ag-theme-alpine content-section-container`} >
                  {
                    (payrollRun?.account?.payrollSettings !== null && payrollRun?.account?.payrollSettings !== undefined) &&
                    <div className={`content-data-text  me-4 `}>
                      Pay Period: <b>{renderPayrollPeriod(payrollRun.account?.payrollSettings?.payrollPeriod)}</b>
                    </div>
                  }
                  <AgGridReact
                    className='no-header'
                    rowData={rowData}
                    columnDefs={columnDefs}
                    ref={gridRef}
                    defaultColDef={defaultColDef}
                    onGridReady={onGridReady}
                    onColumnSizeChanged={onColumnsSizeChanged}
                    onGridSizeChanged={onGridSizeChanged}
                    onCellEditingStopped={onCellEditingStopped}
                    onCellKeyDown={onCellKeyDown}
                    rowSelection={'single'}
                    rowHeight={70}
                    onFirstDataRendered={onFirstDataRendered}
                    tooltipShowDelay={1000}
                    tooltipHideDelay={2000}
                    onCellClicked={onCellClicked}
                    onCellDoubleClicked={onCellDoubleClicked}
                  >
                  </AgGridReact>
                </div>
              </div>
            </div>
            {
              (!editablePage &&
                <div className='content-body-container payroll-footer-container row non-printable'>
                  <div className="col-12">
                    <div className="content-section-container d-flex justify-content-end">
                      <button type='button' onClick={togglePayrollRun} className={`btn btn-lg me-2 btn-primary`} disabled={editablePage}>Run Payroll</button>
                    </div>
                  </div>
                </div>
              )
            }
            <PayrollRunModal modal={modal} setModal={setModal} toggle={toggle} togglePayrollRun={togglePayrollRun} runPayroll={runPayroll} />
            <PaystubReportMenuModal modal={reportMenuModal} setModal={setReportMenuModal} toggle={toggleReportMenuModal} payrollRunLineItems={payrollRunLineItems} startDate={payrollRun?.startDate} endDate={payrollRun?.endDate} payrollRunId={state?.payrollRunId} />
            <EmployerRemittanceReportMenuModal modal={employerRemittanceMenuModal} setModal={setEmployerRemittanceMenuModal} toggle={toggleEmployerRemittanceReportMenuModal} payrollRun={payrollRun} />
            <PayrollReportMenuModal modal={payrollReportMenuModal} setModal={setPayrollReportMenuModal} toggle={togglePayrollReportMenuModal} payrollRunId={state?.payrollRunId} />
          </>
        )
      }
    </div>
  )
}

export default EditPayrollPage
