import React, { useState, useEffect, useRef } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { addQuoteItem } from '../../services/quoteServices'
import _ from 'lodash'
import { showToast } from '../../utils/toastUtils';
import Select from 'react-select'
import { provinceCheck } from '../../utils/provinceUtils';
import { NumericFormat } from 'react-number-format';
import { removeNumberFormat } from '../../utils/formatUtils';
import { useSelector } from 'react-redux';
import { TAX_TYPES } from '../../constants';
import { isABMUser } from '../../utils/roleUtils';

function QuoteItemForm({
  itemList,
  customerId,
  quoteId,
  province,
  fillQuoteData,
  fillTableData,
  gLCodeList = {},
  setSubtotal,
  setDiscount,
  setTotal,
  setTax,
  oldValues,
  focusOnLastRow,
  scrollToLastRow,
  taxSelectRef
}) {
  var options = []
  const accountId = useSelector((state) => state.auth.user.accountId);
  const userType = useSelector((state) => state.auth.user.userType);
  const { control, register, handleSubmit, setValue, clearErrors, watch, reset, setFocus, formState: { errors } } = useForm();
  const [selectedExpense, setSelectedExpense] = useState(false);
  const [isManualFormHidden, setIsManualFormHidden] = useState(true);
  const [disabledtaxRateField, setDisabledTaxRateField] = useState(true)
  const accountState = useSelector((state) => state.account.selectedAccount);
  
  if (itemList !== undefined) {
    options = itemList.map((item, index) => ({
      value: index,
      label: item.labourDescription !== undefined ? item.labourDescription : item.expenseDescription
    }));
  }

  //Field watchers
  const quantity = watch("quantity");
  const quantityMobile = watch("quantityMobile");
  const rate = watch("rate");
  const taxRate = watch("taxRate");
  const discount = watch("discount");
  const taxType = watch("taxType");
  const taxSelect = watch("taxSelect");

  // const taxSelectRef = useRef(null);

  

  useEffect(() => {
    taxSelectRef.current.focus();
  }, [])

  setFocus("taxSelect");

  useEffect(() => {
    if (!isManualFormHidden) {
      if (taxType !== undefined && !isNaN(taxType)) {
        if (taxType === 5) {
          if (selectedExpense?.taxType === 5) {
            setValue("taxRate", (provinceCheck(province, taxType, selectedExpense?.taxRate) * 100).toFixed(2));
          } else {
            setValue("taxRate", (provinceCheck(province, taxType) * 100).toFixed(2));
          }
          setDisabledTaxRateField(false)
        } else {
          setDisabledTaxRateField(true)
          setValue("taxRate", (provinceCheck(province, taxType) * 100).toFixed(2));
        }
      }
    }

  }, [taxType])

  const onSubmit = async (payload) => {
    let response = null;

    if (!isManualFormHidden) {
      _.set(payload, 'rate', removeNumberFormat(payload.rate));
      _.set(payload, 'discount', removeNumberFormat(payload.discount));
      _.set(payload, 'subtotal', removeNumberFormat(payload.subtotal));
      _.set(payload, 'total', removeNumberFormat(payload.total));
      _.set(payload, 'totalTax', removeNumberFormat(payload.totalTax));
      _.set(payload, 'taxRate', removeNumberFormat(payload.taxRate) / 100);
    }

    _.set(payload, 'taxSelect', null);

    if(isABMUser(userType)) {
      _.set(payload, 'accountId', accountState.accountId != null ? accountState.accountId : accountId);
    } else {
      _.set(payload, 'accountId', accountId);
    }

    response = await addQuoteItem(payload);
    if (response.ok) {
      setIsManualFormHidden(true);
      clearErrors();
      reset();
      if(!_.isNil(taxSelectRef.current)) {
        taxSelectRef.current.clearValue()
      }
    }
    showToast({
      type: `${response.ok ? 'success' : 'error'}`,
      message: `${response.ok ? 'Successfully added' : 'Failed to add'} item.`
    });

    if (response.ok) {
      fillTableData();
      fillQuoteData();

      setTimeout(() => {
        if(isManualFormHidden) {
          focusOnLastRow();
        } else {
          taxSelectRef.current.focus();
          scrollToLastRow();
        }
      }, 500);
      
    }
  }

  // clears values on form type change
  useEffect(() => {
    reset();
    setSubtotal(oldValues.subtotal);
    setDiscount(oldValues.discount);
    setTotal(oldValues.total);
    setTax(oldValues.tax);
    setValue('quoteItemId', 0);
    // setValue('accountId', accountId);
    if(isABMUser(userType)) {
      setValue('accountId', accountState.accountId != null ? accountState.accountId : accountId);
    } else {
      setValue('accountId', accountId);
    }
    setValue('customerId', customerId);
    setValue('quoteId', quoteId);
    setValue('quantity', 0);
    setValue('quantityMobile', 0);
    setValue('discount', 0);

    if(isManualFormHidden) {
      taxSelectRef.current.focus()
    } else {
      setTimeout(() => {
        setFocus("description");
      }, 50);
    }
    
  }, [isManualFormHidden])

  // sets value when the form is using the "template" type
  useEffect(() => {

    setSelectedExpense(taxSelect);
    if ((taxSelect !== undefined && taxSelect !== null) && gLCodeList !== undefined) {
      const taxType = parseInt(itemList[taxSelect.value]["taxType"]);
      const defaultRate = parseFloat(itemList[taxSelect.value]["defaultRate"]);
      const glCode = parseInt(itemList[taxSelect.value]["glCodeId"]);
      setValue("description", taxSelect.label);
      setValue("rate", 0);
      setValue("taxType", taxType);
      setValue("taxRate", defaultRate);
      setValue("discount", 0);
      setValue("subtotal", 0);
      setValue("total", 0);
      setValue("totalTax", 0);
      setValue('quoteItemId', 0);
      if(isABMUser(userType)) {
        setValue('accountId', accountState.accountId != null ? accountState.accountId : accountId);
      } else {
        setValue('accountId', accountId);
      }
      setValue('customerId', customerId);
      setValue('quoteId', quoteId);
      setValue('glCodeId', glCode);
    }
  }, [taxSelect])

  const numberCheck = (value) => {
    return value > 0 ? value : 0;
  }

  // Calculations when creating an item manually
  useEffect(() => {
    const quantityCheck = numberCheck(quantity);
    const rateCheck = numberCheck(parseFloat(removeNumberFormat(rate)));
    const discountCheck = numberCheck(parseFloat(removeNumberFormat(discount)));
    const taxRateCheck = numberCheck(parseFloat((removeNumberFormat(taxRate) / 100)));

    const subtotal = parseFloat(quantityCheck * rateCheck) - discountCheck;
    const totalTax = parseFloat(subtotal * taxRateCheck);
    const total = parseFloat(subtotal + totalTax);

    setValue("subtotal", numberCheck(subtotal));
    setValue("total", numberCheck(total));
    setValue("totalTax", numberCheck(totalTax));

    //set parent values
    setSubtotal(oldValues.subtotal + numberCheck(subtotal));
    setDiscount(oldValues.discount + discountCheck);
    setTotal(oldValues.total + numberCheck(total));
    setTax(oldValues.tax + numberCheck(totalTax));
  }, [quantity, rate, taxRate, discount])

  useEffect(() => {
    setValue("quantity", quantityMobile);
  }, [quantityMobile])



  return (
    <>
      <div className="form-title d-flex justify-content-center mb-4">
        Add Line Entry
      </div>
      <form className='form-container' id="account-form" onSubmit={handleSubmit(onSubmit)}>
        {isManualFormHidden && (
          <div>
            <div className='d-none d-md-block'>
              <div className='row'>
                <div className='col-2'></div>
                <div className='col-8'>
                  <div className="d-flex justify-content-center align-items-center">
                    <div className="custom-selector me-4">
                      <Controller
                        name="taxSelect"
                        control={control}
                        render={({ field }) => (
                          <Select
                            onChange={field.onChange}
                            value={field.value}
                            ref={taxSelectRef}
                            options={options}
                            placeholder={'Select Expense'}
                            id="expense"
                            isClearable
                            // onFocus={field.ref.current.focus()}
                          />
                        )}
                      />
                    </div>
                    {!selectedExpense && (
                      <>
                        <p className='mb-0 me-4 fw-bold'>
                          <small>or</small>
                        </p>
                        <button type="button" className="btn btn-primary" style={{ width: 160 }} onClick={() => setIsManualFormHidden(false)}>Manual Entry</button>
                      </>
                    )}
                    {selectedExpense && (
                      <>
                        <div style={{ width: 100 }} className='me-4'>
                          <input type="number" step="any" className="form-control" id="quantity" placeholder="Quantity" style={{ padding: 10 }}
                            {...register("quantity", { valueAsNumber: true, required: "Quantity is required" })}
                          />
                        </div>
                        <button type="submit" className="btn btn-primary" style={{ padding: 10 }}>Save</button>
                      </>
                    )}
                  </div>
                </div>
                <div className='col-2'></div>
              </div>
            </div>
            <div className='d-md-none'>
              <div className='row'>
                <div className='col-12 mb-2'>
                  <Controller
                    name="taxSelect"
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={options}
                        placeholder={'Select Expense'}
                        id="expense"
                        isClearable
                      />
                    )}
                  />
                </div>
                {!selectedExpense && (
                  <>
                    <div className='col-12 opacity-50'>
                      <hr></hr>
                    </div>
                    <div className='col-12'>
                      <button type="button" className="btn btn-primary w-100" style={{ width: 160 }} onClick={() => setIsManualFormHidden(false)}>Manual Entry</button>
                    </div>
                  </>
                )}
                {selectedExpense && (
                  <>
                    <div className='col-6'>
                      <input type="number" step="any" className="form-control" id="quantityMobile"
                        {...register("quantityMobile", { valueAsNumber: true, required: "Quantity is required" })}
                      />
                    </div>
                    <div className='col-6'>
                      <button type="submit" className="btn btn-primary w-100">Save</button>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        )}
        {!isManualFormHidden && (
          <div className='row'>
            <div className="col-6 col-sm-4 mb-2">
              <label htmlFor="description" className="form-label">Description</label>
              <input type="text" className="form-control" id="description"
                {...register("description", { required: "Description is required" })}
              />
              <small className='form-error-message'>
                {errors?.description && errors.description.message}
              </small>
            </div>
            <div className="col-6 col-sm-4 mb-2">
              <label htmlFor="glCode" className="form-label">GL Code</label>
              <select
                className="form-select"
                id="glCode"
                {...register("glCodeId", { valueAsNumber: true, required: "GL Code is required" })}
              >
                <option key="none" value="" hidden>GL Code</option>
                {gLCodeList.map((gl, index) =>
                  <option key={index} value={parseInt(gl.glCodeId)}>{gl.description}</option>
                )}
              </select>
              <small className='form-error-message'>
                {errors?.glCodeId && errors.glCodeId.message}
              </small>
            </div>
            <div className="col-6 col-sm-4 mb-2">
              <label htmlFor="quantity" className="form-label">Quantity</label>
              <input type="number" step="any" className="form-control" id="quantity"
                {...register("quantity", { valueAsNumber: true, required: "Quantity is required" })}
              />
              <small className='form-error-message'>
                {errors?.quantity && errors.quantity.message}
              </small>
            </div>
            <div className="col-6 col-sm-4 mb-2">
              <label htmlFor="rate" className="form-label">Unit Price</label>
              <Controller
                control={control}
                name='rate'
                register={register}
                rules={{ required: "Unit Price is required" }}
                render={({ field: { onChange, onBlur, name, value, ref } }) => (
                  <NumericFormat
                    name={name}
                    value={value === undefined ? '' : value}
                    onChange={onChange}
                    onBlur={onBlur}
                    className='form-control'
                    prefix="$"
                    thousandsGroupStyle="thousand"
                    thousandSeparator=","
                    id="rate"
                    mask="_"
                    decimalScale={2} 
                    fixedDecimalScale
                    />
                )}
              />
              <small className='form-error-message'>
                {errors?.rate && errors.rate.message}
              </small>
            </div>
            <div className="col-6 col-sm-4 mb-2">
              <label htmlFor="taxType" className="form-label">Tax Type</label>
              <select
                className="form-select"
                id="taxType"
                {...register("taxType", { valueAsNumber: true, required: "Tax Type is required" })}
              >
                <option key="none" value="" hidden>Tax Type</option>
                {TAX_TYPES.map((tax, index) =>
                  <option key={index} value={tax.value}>{tax.label}</option>
                )}
              </select>
              <small className='form-error-message'>
                {errors?.taxType && errors.taxType.message}
              </small>
            </div>
            <div className="col-6 col-sm-4 mb-2">
              <label htmlFor="taxRate" className="form-label">Tax Rate</label>
              <Controller
                control={control}
                name='taxRate'
                register={register}
                rules={{ valueAsNumber: true }}
                render={({ field: { onChange, onBlur, name, value, ref } }) => (
                  <NumericFormat value={value} name={name} onChange={onChange} onBlur={onBlur} className='form-control' suffix="%" thousandsGroupStyle="thousand" thousandSeparator=","  id="taxRate"mask="_" disabled={disabledtaxRateField} />
                )}
              />
              <small className='form-error-message'>
                {errors?.taxRate && errors.taxRate.message}
              </small>
            </div>
            <div className="col-6 col-sm-4 mb-2">
              <label htmlFor="discount" className="form-label">Discount</label>
              <Controller
                control={control}
                name='discount'
                register={register}
                rules={{ required: "Discount is required" }}
                render={({ field: { onChange, onBlur, name, value, ref } }) => (
                  <NumericFormat
                    value={value === undefined ? '' : value}
                    name={name}
                    onChange={onChange}
                    onBlur={onBlur}
                    className='form-control'
                    prefix="$"
                    thousandsGroupStyle="thousand"
                    thousandSeparator=","
                    id="discount"
                    mask="_"
                    decimalScale={2} 
                    fixedDecimalScale
                    />
                )}
              />
              <small className='form-error-message'>
                {errors?.discount && errors.discount.message}
              </small>
            </div>
            <div className="col-6 col-sm-4 mb-2">
              <label htmlFor="subtotal" className="form-label">Subtotal</label>
              <Controller
                control={control}
                name='subtotal'
                register={register}
                rules={{ valueAsNumber: true }}
                render={({ field: { onChange, onBlur, name, value, ref } }) => (
                  <NumericFormat name={name} value={value} onChange={onChange} onBlur={onBlur} className='form-control' prefix="$" thousandsGroupStyle="thousand" thousandSeparator="," id="subtotal" mask="_" decimalScale={2} disabled fixedDecimalScale/>
                )}
              />
              <small className='form-error-message'>
                {errors?.subtotal && errors.subtotal.message}
              </small>
            </div>
            <div className="col-6 col-sm-4 mb-2">
              <label htmlFor="total" className="form-label">Total</label>
              <Controller
                control={control}
                name='total'
                register={register}
                rules={{ valueAsNumber: true }}
                render={({ field: { onChange, onBlur, name, value, ref } }) => (
                  <NumericFormat name={name} value={value} onChange={onChange} onBlur={onBlur} className='form-control' prefix="$" thousandsGroupStyle="thousand" thousandSeparator="," id="total" mask="_" decimalScale={2} disabled fixedDecimalScale/>
                )}
              />
              <small className='form-error-message'>
                {errors?.accountName && errors.accountName.message}
              </small>
            </div>
            <div className="col-6 col-sm-4 mb-2">
              <label htmlFor="totalTax" className="form-label">Total Tax</label>
              <Controller
                control={control}
                name='totalTax'
                register={register}
                rules={{ valueAsNumber: true }}
                render={({ field: { onChange, onBlur, name, value, ref } }) => (
                  <NumericFormat name={name} value={value} onChange={onChange} onBlur={onBlur} className='form-control' prefix="$" thousandsGroupStyle="thousand" thousandSeparator="," id="totalTax" mask="_" decimalScale={2} disabled fixedDecimalScale/>
                )}
              />
              <small className='form-error-message'>
                {errors?.accountName && errors.accountName.message}
              </small>
            </div>
            <div className="d-flex flex-row-reverse mt-3">
              <button type="submit" className="btn btn-primary">Save</button>
              <button type="button" className="btn btn-primary me-2" onClick={() => setIsManualFormHidden(true)}>Cancel</button>
            </div>
          </div>
        )}
      </form>
    </>
  )
}

export default QuoteItemForm