import React, { useState, useMemo, useCallback, useRef, useEffect } from 'react'
import { AgGridReact } from 'ag-grid-react';
import StatusRenderer from '../Templates/CellRenderers/StatusRenderer';
import ActionRenderer from '../Templates/CellRenderers/ActionRenderer';
import ContentHeader from '../Commons/Layouts/ContentHeader';
import ProfilePictureRenderer from '../Templates/CellRenderers/Commons/ProfilePictureRenderer';
import {formatDateOnly, formatCurrency} from '../../utils/formatUtils'
import { fetchQuotes, fetchQuotesFiltered } from '../../services/quoteServices'
import { statusValueGetter, statusFilter } from '../../utils/quoteUtils';
import { isSuperAdmin, isABMUser } from '../../utils/roleUtils';
import QuoteForm from './QuoteForm';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { setQuotesListData } from '../../redux/quote/quoteReducer';
import { fetchCustomersByAccount } from '../../services/customerServices';
import { setCustomersListData } from '../../redux/customer/customerReducer';

function Quotes() {
  const dispatch = useDispatch();
  const gridRef = useRef();
  const openOrCloseButtonRef = useRef();
  const accountId = useSelector((state) => state.auth.user.accountId)
  const userType = useSelector((state) => state.auth.user.userType);
  const selectedAccountState = useSelector((state) => state.account.selectedAccount);
  const quoteState = useSelector((state) => state.quote);

  const toggleFormDisplay = (isEdit = false, quoteId = null) => {
    fillCustomerList();
    if (isFormHidden) {
      setSelectedQuoteId(quoteId);
    } else {
      setSelectedQuoteId(null);
    }

    if (modal) {
      setModal(!modal)
    }

    setIsFormEdit(isEdit);
    setIsFormHidden(!isFormHidden);
    setTimeout(() => {
      gridRef.current.api.sizeColumnsToFit();
    }, 50);
  }

  const toggleFormCancelDisplay = () => {
    if (isFormDirty) {
      toggle()
      return
    }
    toggleFormDisplay()
  }

  const [rowData, setRowData] = useState([]);
  const defaultColumnDefs = [
    { field: '#', width: 50, minWidth: 50, suppressSizeToFit: true, cellStyle: { fontWeight: 'bold' } },
    {
      field: 'ProfilePicture',
      headerName: '',
      minWidth: 70,
      cellRenderer: ProfilePictureRenderer,
      width: 80,
      suppressSizeToFit: true,
      cellClass: 'no-borders',
    },
    {
      field: 'Customer',
      autoHeight: true,
      cellClass: 'no-borders',
      width: 120,
      minWidth: 120,
      filter: "agTextColumnFilter",
      sortable: true,
      getQuickFilterText: params => { return params.value; }
    },
    { field: 'QuoteName', minWidth: 120, autoHeight: true, cellClass: 'no-borders' },
    { field: 'Discount', minWidth: 110, cellClass: 'no-borders', width: 120, getQuickFilterText: params => { return params.value; } },
    { field: 'Subtotal', minWidth: 110, cellClass: 'no-borders', width: 120, getQuickFilterText: params => { return params.value; } },
    { field: 'Total', minWidth: 110, cellClass: 'no-borders', width: 120, getQuickFilterText: params => { return params.value; } },
    { field: 'Tax', minWidth: 110, cellClass: 'no-borders', width: 120, getQuickFilterText: params => { return params.value; } },
    {
      field: 'Created',
      minWidth: 110,
      cellClass: 'no-borders',
      sort: 'desc',
      width: 120,
      sortable: true,
      getQuickFilterText: params => { return params.value; }
    },
    {
      field: 'Status',
      cellRenderer: StatusRenderer,
      autoHeight: true,
      cellClass: 'no-borders',
      minWidth: 120,
      width: 120,
      filter: "agTextColumnFilter",
      valueGetter: statusValueGetter,
      sortable: true,
      getQuickFilterText: params => { return params.value; }
    },
    {
      field: 'Actions', 
      minWidth: 110, 
      cellRenderer: ActionRenderer, 
      cellRendererParams: { gridRef: gridRef, rowData: rowData, setRowData}, 
      autoHeight: true, cellClass: 'd-flex flex-row-reverse' 
    },
  ];

  const columnDefsWithRow = [
    { field: '#', width: 50, minWidth: 50, suppressSizeToFit: true },
    { field: 'ProfilePicture', minWidth: 70, headerName: '', cellRenderer: ProfilePictureRenderer, width: 80, suppressSizeToFit: true },
    { field: 'Customer', minWidth: 150 },
    { field: 'QuoteName', minWidth: 150 },
  ];

  // useStates
  const [columnDefs, setColumnDefs] = useState(defaultColumnDefs);
  const [isFormHidden, setIsFormHidden] = useState(true);
  const [quotesList, setQuotesList] = useState([]);
  const [isFormEdit, setIsFormEdit] = useState(false);
  const [selectedQuote, setSelectedQuote] = useState({});
  const [selectedQuoteId, setSelectedQuoteId] = useState(null);
  const [isFormDirty, setIsFormDirty] = useState(null);
  const [modal, setModal] = useState(false);
  const [customersFetched, setCustomersFetched] = useState(false);
  const toggle = () => setModal(!modal);

  // useEffects
  useEffect(() => {
    init();
    focusOnOpenOrCloseButton();
  }, [])

  useEffect(() => {
    setQuotesList(quoteState.data);
  }, [quoteState.data]);

  useEffect(() => {
    setColumnDefs(isFormHidden ? defaultColumnDefs : columnDefsWithRow);
  }, [isFormHidden]);

  useEffect(() => {
    let data = [];
    _.each(quotesList, (quote, index) => {
      data.push({
        '#': index + 1,
        'ProfilePicture': { url: `images/profile-picture-fallback.png` },
        'Customer': quote.customer.customerName,
        'QuoteName': quote.quoteName,
        'Discount': formatCurrency(quote.discount),
        'Subtotal': formatCurrency(quote.subtotal),
        'Total': formatCurrency(quote.totalQuote),
        'Tax': formatCurrency(quote.totalTax),
        'Status': quote.status,
        'Created': formatDateOnly(quote.quoteDate),
        'id': quote.quoteId
      });
    });
    setRowData(data);
  }, [quotesList])

  useEffect(() => {
    fillSelectedQuote(selectedQuoteId);
  }, [selectedQuoteId]);

  const defaultColDef = useMemo(() => {
    return {
      cellStyle: {
        whiteSpace: 'pre-wrap',
        overflowWrap: 'break-word',
        textAlign: 'left',
        lineHeigh: 0,
      },
      resizable: true,
    };
  }, []);

  const init = async () => {
    const response = await getQuotesData();
    dispatch(setQuotesListData(response));
  }

  const getQuotesData = async () => {
    if(isSuperAdmin(userType)) {
      return await fetchQuotes();
    }
    
    if (isABMUser(userType)) {
      return selectedAccountState.accountId === null ? await fetchQuotesFiltered(accountId) : await fetchQuotesFiltered(selectedAccountState.accountId); 
    }

    return await fetchQuotesFiltered(accountId);
  }
  
  const fillCustomerList = async () => {
    if (!customersFetched) {
      const response = await getCustomersData();
      dispatch(setCustomersListData(response));
      setCustomersFetched(true);
    }
  }

  const getCustomersData = async () => {
    return await fetchCustomersByAccount(userType, selectedAccountState.accountId, accountId);
  }

  const onGridReady = useCallback((params) => {
    gridRef.current.api.sizeColumnsToFit();
    statusFilter(gridRef);
  }, []);

  const fillSelectedQuote = (id) => {
    const quote = _.find(quotesList, { quoteId: id });
    setSelectedQuote(quote);
  }
  const filterData = (searchQuery) => {
    gridRef.current.api.setQuickFilter(searchQuery);
  }

  const onColumnsSizeChanged = (params) => {
    var gridWidth = document.getElementById("grid-wrapper").offsetWidth;
    var columnsToShow = [];
    var columnsToHide = [];
    var totalColsWidth = 0;
    var allColumns = params.columnApi.getAllColumns();
    for (var i = 0; i < allColumns.length; i++) {
      let column = allColumns[i];
      totalColsWidth += column.getMinWidth();
      if (totalColsWidth > gridWidth) {
        columnsToHide.push(column.colId);
      } else {
        columnsToShow.push(column.colId);
      }
    }
    params.columnApi.setColumnsVisible(columnsToShow, true);
    params.columnApi.setColumnsVisible(columnsToHide, false);
    params.api.sizeColumnsToFit();
  }

  const onGridSizeChanged = (params) => {
    params.api.sizeColumnsToFit();
  }

  const focusOnOpenOrCloseButton = () => {
    if(!isSuperAdmin(userType)) {
      openOrCloseButtonRef.current.focus();
    }
    
  }

  return (
    <div>
      <div className='content-header-container d-flex flex-row align-items-center'>
        <div className='me-auto'>
          <ContentHeader title="Quotes" dataCount={rowData.length} filterData={filterData} />
        </div>
        {!isSuperAdmin(userType) &&
          <button
            className={`btn btn-lg ${isFormHidden ? 'btn-primary' : 'btn-secondary'}`}
            type="submit"
            onClick={() => toggleFormCancelDisplay()}
            ref={openOrCloseButtonRef}
          >
            {isFormHidden ? 'Add Quote' : 'Close'}
          </button>
        }
      </div>
      <div className="content-body-container row">
        <div className={`${isFormHidden ? 'col-12' : 'col-12 col-lg-5 min-vh-25'} mb-2`}>
          <div className={`ag-theme-alpine content-section-container`} >
            <AgGridReact
              className='no-header'
              rowData={rowData}
              columnDefs={columnDefs}
              ref={gridRef}
              defaultColDef={defaultColDef}
              onGridReady={onGridReady}
              onColumnSizeChanged={onColumnsSizeChanged}
              onGridSizeChanged={onGridSizeChanged}
              rowHeight={70}
              rowSelection={'single'}
            >
            </AgGridReact>
          </div>
        </div>
        <div className={`col-12 col-lg-7 ${isFormHidden ? 'd-none' : ''}`}>
          <QuoteForm
            isFormEdit={isFormEdit}
            selectedQuote={selectedQuote}
            isFormHidden={isFormHidden}
            toggleFormDisplay={toggleFormDisplay}
            setIsFormDirty={setIsFormDirty}
            modal={modal}
            setModal={setModal}
            toggle={toggle}
            focusOnOpenOrCloseButton={focusOnOpenOrCloseButton}
          />
        </div>
      </div>
    </div>
  )
}

export default Quotes
