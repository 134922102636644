import React, { useState, useRef, useEffect, memo, forwardRef, useImperativeHandle } from 'react'
import { Input } from 'reactstrap'
import { provinceCheck } from '../../../utils/provinceUtils';
import { TAX_TYPES } from '../../../constants';

export default memo(forwardRef((props, ref) => {

  const refInput = useRef(null);
  const [value, setValue] = useState();

  useEffect(() => {
    // focus on the input
    setTimeout(() => refInput.current.focus());
  }, []);

  const setProvince = (province, value) => {
    var taxRate = provinceCheck(province, value);

    const selectedNode = props.gridRef.current.api.getSelectedNodes();
    selectedNode[0].data["Tax Rate"] = taxRate;
  }

  useImperativeHandle(ref, () => {
    return {
      // the final value to send to the grid, on completion of editing
      getValue() {
        setProvince(props.province(), parseInt(value));
        if (value === undefined) {
          return parseInt(props.data["Tax Type"]);
        }
        return value;
      },
    };
  });

  return (
    <div className="cell-editor">
      <Input
        id="taxable"
        name="select"
        type="select"
        defaultValue={props.value}
        innerRef={refInput}
        className="cell-editor-input"
        onChange={(event) => setValue(event.target.value)}
      >
        <option key="none" value="" hidden>Default Tax Code</option>
        {TAX_TYPES.map((taxCode, index) =>
          <option key={index} value={taxCode.value}>{taxCode.label}</option>
        )}
      </Input>
    </div>

  )
}));
