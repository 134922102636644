import moment from "moment-timezone";
import JSZip from 'jszip';
import * as FileSaver from 'file-saver';

export function nextPayrollDateRange(endDate, period, isFirstPayroll = false) {
  var currentDate = moment(endDate)
  var nextStartDate = currentDate.clone().add(1, "day");
  if(isFirstPayroll) {
    nextStartDate = currentDate.clone();
  }
  var nextEndDate = "";

  if(period === 1) {
    nextEndDate = currentDate.clone().add(isFirstPayroll? 6 : 7, "days");
  }

  if (period === 2) {
    nextEndDate = currentDate.clone().add(isFirstPayroll? 13 : 14, "days");
  }

  if (period === 3) {
    var secondHalfOfTheMonth = nextStartDate.clone().startOf('month').add(14, 'days');

    if (nextStartDate.isSameOrAfter(secondHalfOfTheMonth)) {
      nextStartDate = secondHalfOfTheMonth.clone().add(1, 'days');
      nextEndDate = nextStartDate.clone().endOf('month').set({ hour: 0, minute: 0, second: 0 });
    } else {
      nextStartDate = nextStartDate.clone().startOf('month');
      nextEndDate = nextStartDate.clone().add(14, 'days');
    }
  }

  if (period === 4) {
    nextStartDate = nextStartDate.clone().startOf('month');
    nextEndDate = nextStartDate.clone().endOf('month').set({ hour: 0, minute: 0, second: 0 });
  }

  return [nextStartDate.toDate(), nextEndDate.toDate()]
}

export function renderPayrollPeriod(period) {
  var payrollPeriod = "Weekly";

  if(period === 2) {
    payrollPeriod = "Bi-Weekly"
  }

  if(period === 3) {
    payrollPeriod = "Semi-Monthly"
  }

  if(period === 4) {
    payrollPeriod = "Monthly"
  }

  return payrollPeriod
}

export function printPreviewReportWithBase64(base64string) {
  const pdfBlob = b64toBlob(base64string, 'application/pdf');
  const url = URL.createObjectURL(pdfBlob);
  
  const iframe = document.createElement('iframe');
  iframe.src = url;
  iframe.style.display = 'none';
  iframe.setAttribute("download", "test.pdf");
  iframe.download = url + 'pdf';
  document.body.appendChild(iframe);
  
  

  iframe.onload = () => {
    iframe.contentWindow.open(url);
    
    setTimeout(() => {
      iframe.remove();
    }, 0);
  };
}

export const downloadPaystubsInZip = async (payrollRunLineItems, employeeName, reportEndDate) => {
  const zip = new JSZip();
  const promises = [];
  payrollRunLineItems.forEach(payrollRunLineItem => {
    const fileName = `${payrollRunLineItem.userName} ${moment(payrollRunLineItem.payrollRun.endDate).format('MMM-DD-YYYY')}.pdf`; 
    const formattedFileName = fileName.replace(/\s+/g, '-'); 
    zip.file(formattedFileName, b64toBlob(payrollRunLineItem?.paystubReport));
  });
  // Wait for all promises to resolve
  await Promise.all(promises);
  // Generate and download the zip file
  zip.generateAsync({ type: 'blob' }).then(blob => {
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;

    const fileName = `${employeeName} ${moment(reportEndDate).format('MMM-DD-YYYY')}.zip`;
    const formattedFileName = fileName.replace(/\s+/g, '-'); 

    link.download = formattedFileName;
    // Append the link to the document body
    document.body.appendChild(link);
    // Programmatically click the link to trigger the download
    link.click();
    // Remove the link from the document body after download
    document.body.removeChild(link);
  });
}

export async function downloadReportWithBase64(payrollRunLineItem) {
  const pdfBlob = b64toBlob(payrollRunLineItem?.paystubReport, 'application/pdf');
  const url = URL.createObjectURL(pdfBlob);

  const link = document.createElement('a');
  link.href = url;
  const fileName = `${payrollRunLineItem.userName} ${moment(payrollRunLineItem.payrollRun.endDate).format('MMM-DD-YYYY')}.pdf`; 
  const formattedFileName = fileName.replace(/\s+/g, '-'); 
  link.download = formattedFileName; // Set the filename for download

  // Append the link to the document body
  document.body.appendChild(link);

  // Programmatically click the link to trigger the download
  link.click();

  // Remove the link from the document body after download
  document.body.removeChild(link);
}

const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

export const editPayMethod = (params, isEditable) => {

  if (params.data['Pay Method Type'] === 0) {
    return false;
  }

  return isEditable;
}

export const editOvertimePayUnit = (params, isEditable) => {
  if (params.data['Pay Method Type'] !== 1) {
    return false;
  }

  return isEditable;
}

export const overtimeValueGetter = (params) => {
  if (params.data['Pay Method Type'] !== 1) {
    return "n/a";
  }

  return params.data["Overtime Pay Unit"];
}