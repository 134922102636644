import React from 'react'
import { Tooltip as ReactTooltip } from "react-tooltip";
import { Link } from 'react-router-dom';
function ContentHeader({
  title,
  dataCount,
  filterData,
  setBalanceFilter,
  hideTotal = false,
  hideSearch = false,
  showStatus = false,
  showBalanceFilter = false,
  editablePage = false,
  date = null,
  backButton = false,
  backButtonLink = "",
}) {

  const handleChange = (e) => {
    filterData(e.target.value);
  }

  const handleBalanceFilterChange = (e) => {
    setBalanceFilter(e.target.checked);
  }

  return (
    <div className="d-flex flex-row flex-wrap align-items-center">
      {/* <div className=' me-3'> */}
      <div className={` ${backButton ? 'me-3' : 'd-none'}`}>
        <Link className="back-button" to={backButtonLink}>
          <i className="fa-solid fa-arrow-left"></i>
        </Link>
      </div>
      <div className={`content-title ${showStatus && !editablePage ? 'me-2' : ''}`}>
        {title}
      </div>
      <div className={`icon ${showStatus && !editablePage ? '' : 'd-none'}`}>
        <i id="locked-tooltip" className="fa-solid fa-lock"></i>
        <ReactTooltip
          anchorId="locked-tooltip"
          className='tooltip-container'
          place="top"
          content="This payroll run has already been commited"
        />
      </div>
      <div className={`divider ms-4 me-3 ${hideTotal && hideSearch ? 'd-none' : ''}`}></div>
      <div className={`content-data-count me-4 ${hideTotal ? 'd-none' : ''}`}>
        {dataCount} Total
      </div>

      <div className={`search-input-field-container ${hideSearch ? 'd-none' : ''}`}>
        <div className="input-group">
          <span className="input-group-text" id="basic-addon1">
            <i className="fa-solid fa-search"></i>
          </span>
          <input
            type="text"
            className="search-input-field form-control shadow-none"
            placeholder="Search..."
            id="search"
            onChange={(e) => handleChange(e)}
          />
        </div>
      </div>

      <div className={`form-check balance-filter-container ${showBalanceFilter ? '' : 'd-none'}`}>
        <input className="form-check-input" type="checkbox" value="" id="balanceFilterCheckbox" defaultChecked={true} onChange={(e) => handleBalanceFilterChange(e)} />
        <label className="form-check-label" htmlFor="balanceFilterCheckbox">
          with Balances
        </label>
      </div>
    </div>

  )
}

export default ContentHeader