import React, { useState } from 'react';
import { Sidebar, Menu, MenuItem, SubMenu, useProSidebar } from 'react-pro-sidebar';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import _ from 'lodash';
import { MENU_ITEMS } from '../../constants';
import './sidebar.css';

function SidebarPage(props) {
  const location = useLocation();
  const authState = useSelector((state) => state.auth);
  const { collapseSidebar } = useProSidebar();

  const [labelVisibility, setLabelVisibility] = useState(true);

  const toggleCollapse = () => {
    collapseSidebar();
    setTimeout(() => {
      setLabelVisibility(!labelVisibility);
    }, 85)
  };

  return authState?.isAuthenticated && (
    <Sidebar className='sidebar non-printable d-none d-lg-block' backgroundColor="white">
      <Menu>
        {MENU_ITEMS.map((menuItem, index, array) => 
          // for menu items that doesn't have sub menu items
          _.isNil(menuItem.subMenuItems) ? (
            <div key={index}>
              {index === array.length - 1 ? <hr key="break"></hr> : ''}
              <MenuItem
                routerLink={<Link to={menuItem.link} />}
                className="menuitem"
                disabled={menuItem.disabled ? true : ''}
                icon={menuItem.icon}
              >
                  <div className={`menu-label ${location.pathname.includes(menuItem.link) ? 'selected' : ''} ${menuItem.disabled ? 'disabled' : ''} ${labelVisibility ? '' : 'd-none'}`}>
                    {menuItem.label}
                  </div>
              </MenuItem>
            </div>
          ) : (
            // for menu items that doesn't have sub menu items
            <div key={index}>
              {index === array.length - 1 ? <hr key="break"></hr> : ''}
              <SubMenu label={menuItem.label} icon={menuItem.icon}>
                {menuItem.subMenuItems.map((subMenuItem, index, array) =>
                  <div key={index} className="sub-menu-item">
                    <MenuItem
                      routerLink={<Link to={subMenuItem.link} />}
                      className="menuitem"
                      disabled={subMenuItem.disabled ? true : ''}
                    >
                      <div className={`ms-4 menu-label ${location.pathname.includes(subMenuItem.link) ? 'selected' : ''} ${subMenuItem.disabled ? 'disabled' : ''} ${labelVisibility ? '' : 'd-none'}`}>
                        {subMenuItem.label}
                      </div>
                    </MenuItem>
                  </div>
                )}
              </SubMenu>
            </div>
          )
        )}
        <div className="position-absolute bottom-0 start-0 w-100 mb-5">
          <MenuItem onClick={() => toggleCollapse()} key="collapse">
            <div className="row">
              <div className={`${labelVisibility ? 'col-2' : 'col-12'} menu-icon`}>
                <i className="fa-solid fa-square-minus"></i>
              </div>
              <div className={`col-10 menu-label ${labelVisibility ? '' : 'd-none'}`}>Toggle sidebar</div>
            </div>
          </MenuItem>
        </div>
      </Menu>
    </Sidebar>
  );
}

export default SidebarPage;