import React from 'react'
import { formatDateOnly, formatCurrency, formatPhoneNumber } from '../../utils/formatUtils';

function InvoiceDetails({
  name,
  email,
  phone,
  address,
  estimateNo,
  estimateName,
  estimateDate,
  subtotal,
  discount,
  total,
  tax,
  printMode,
  balance
}) {
  return (
    <div className='content-header-container quote-details-container row'>
      <div className={`${printMode ? 'col-6' : 'col-12'} col-lg-6 mb-2`}>
        <div className='quote-details-container shadow-sm'>
          <div className="content-title mb-3">
            Customer
          </div>
          <div className='row quote-details'>
            <div className="col-6">
              <p className='mb-0 fw-bold'>Name</p>
              <p>{name}</p>
            </div>
            <div className="col-6">
              <p className='mb-0 fw-bold'>Email</p>
              <p>{email}</p>
            </div>
            <div className="col-6">
              <p className='mb-0 fw-bold'>Phone</p>
              <p>{formatPhoneNumber(phone)}</p>
            </div>
            <div className="col-6">
              <p className='mb-0 fw-bold'>Address</p>
              <p>{address}</p>
            </div>
          </div>
        </div>
      </div>

      <div className={`${printMode ? 'col-6' : 'col-12'} col-lg-6 mb-2`}>
        <div className="quote-details-container shadow-sm">
          <div className="content-title mb-3">
            Invoice Details - {balance > 0 ? <span className='text-danger'>{formatCurrency(balance)}</span> : 'Paid in Full'}
          </div>
          <div className='row quote-details'>
            <div className="col-3">
              <p className='mb-0 fw-bold'>Invoice No.</p>
              <p>{estimateNo}</p>
            </div>
            <div className="col-3">
              <p className='mb-0 fw-bold'>Invoice Name</p>
              <p>{estimateName}</p>
            </div>
            <div className="col-3">
              <p className='mb-0 fw-bold'>Invoice Date</p>
              <p>{formatDateOnly(estimateDate)}</p>
            </div>
            <div className="col-3">
              <p className='mb-0 fw-bold'>Created by</p>
              <p>Account User</p>
            </div>
            <div className="col-3">
              <p className='mb-0 fw-bold'>Subtotal</p>
              <p>{formatCurrency(subtotal)}</p>
            </div>
            <div className="col-3">
              <p className='mb-0 fw-bold'>Discount</p>
              <p>{formatCurrency(discount)}</p>
            </div>
            <div className="col-3">
              <p className='mb-0 fw-bold'>Total</p>
              <p>{formatCurrency(total)}</p>
            </div>
            <div className="col-3">
              <p className='mb-0 fw-bold'>Tax</p>
              <p>{formatCurrency(tax)}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default InvoiceDetails