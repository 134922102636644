import numberRenderer from '../components/Templates/CellRenderers/Quotes/numberRenderer';

export const displayStatus = (status) => {
  const obj = {
    0: "Draft",
    1: "Approved",
    2: "Archived"
  }
  return obj[status]
}

export const statusValueGetter = (params) => {
  const obj = {
    0:"Draft",
    1:"Approved",
    2:"Archived"
  }
  return obj[params.data.Status]
}

export function statusFilter(gridRef) {
  var filterComponent = gridRef.current.api.getFilterInstance('Status');
  filterComponent.setModel({
      filterType: 'text',
      type: 'notContains',
      filter: 'archived',
  });
  gridRef.current.api.onFilterChanged();
}

export const currencyRendererSelector = (params) => {
  const title = params.colDef.field
  const selector = params.data[title] !== undefined ? { component: numberRenderer } : undefined
  return selector
} 