import { get, post, patch, remove } from "./apiService";

export const fetchQuotes = async () => {
    return get({ path: 'Quote' });
}

export const fetchQuotesFiltered = async (accountId) => {
  return get({ path: `Quote/filtered` });
}

export const addQuote = async (payload) => {
  return post({ path: 'Quote', body: payload });
}

export const updateQuote = async (payload) => {
  return patch({ path: `Quote/update/${payload.quoteId}`, body: payload });
}

export const addQuoteItem = async (payload) => {
  return post({ path: 'Quote/QuoteItem', body: payload });
}

export const fetchQuoteData = async (state) => {
  return get({ path: `Quote/${state.quoteId}` });
}

export const fetchTableData = async (state) => {
  return get({ path: `Quote/quoteItems/quote/${state.quoteId}` });
}

export const fetchTaxCode = async () => {
  return get({ path: 'Account/taxCode' });
}

export const fetchItemList = async (accountId) => {
  return get({ path: `Quote/expenseCategories` });
}

export const fetchUpdateQuoteItem = async (payload, quoteId) => {
  return patch({ path: `Quote/QuoteItem/${quoteId}`, body: payload });
}

export const fetchUpdateQuoteStatus = async (payload, quoteId) => {
  return patch({ path: `Quote/updateStatus/${quoteId}`, body: payload });
}

export const fetchUpdateQuoteNote = async (payload, quoteId) => {
  return patch({ path: `Quote/note/${quoteId}`, body: payload });
}

export const deleteQuote = async (account) => {
  return remove({ path: `Quote/${account}` });
}

export const deleteQuoteItem = async (account) => {
  return remove({ path: `Quote/QuoteItem/${account}` });
}