import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

export const ConfirmationModal = ({
  isOpen,
  toggle,
  onClick,
  headerText,
  bodyText,
  confirmButtonText,
  cancelButtonText,
  buttonColor
}) => (
  <Modal isOpen={isOpen} toggle={toggle}>
    <ModalHeader toggle={toggle}>{headerText}</ModalHeader>
    <ModalBody>{bodyText}</ModalBody>
    <ModalFooter>
      <Button color={buttonColor} onClick={onClick}>
        {confirmButtonText}
      </Button>{' '}
      <Button color="secondary" onClick={toggle}>
        {cancelButtonText}
      </Button>
    </ModalFooter>
  </Modal>
);