import React from 'react'

function EditFormRenderer(props) {
  const data = props.data["Edit"]

  const toggleFormDisplay = () => {
    props.toggleFormDisplay(true, data.id);
  }

  return (
    <div className='cell-renderer-content d-flex flex-row-reverse'>
      <button className="btn btn-primary btn-sm" type="submit" onClick={() => toggleFormDisplay()}>
        <i className='fa-solid fa-pen'></i>
      </button>
    </div>
  )
}

export default EditFormRenderer
